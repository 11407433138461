import {type MaybeRefOrGetter} from 'vue';
import type {SeoFallback} from '~/interfaces/seo';


export const useEtherSeo = (data: MaybeRefOrGetter, opts: SeoFallback) => {
    const config = useRuntimeConfig();
    const cmsSeo = useSeo(data?.seo, data, opts);

    const seo = computed(() => ({
        ...cmsSeo.value,
        title: cmsSeo.value?.title ?? opts?.seoTitle ?? config.public?.appName,
        htmlAttrs: {
            lang: 'en',
        },
    }));

    if (!seo.value.title) {
        console.warn('SEO title is empty');
    } else {
        useHead(seo);
    }
};
